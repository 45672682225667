<!--表格导入附件-->
<template>
  <div class="annex-upload">
    <el-upload
        :action="annexAction"
        :show-file-list="false"
        list-type="text"
        :auto-upload="true"
        :before-upload="annexUpload"
        :on-error="annexError"
        :on-success="annexSuccess"
        :multiple="false"
        :limit="9"
        :on-exceed="annexExceed"
        name="excel"
        :data="annexData"
    >
      <el-button size="small" type="info" style="margin-left: 10px">上传表格</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['commonExcelImportProp','supplierData'],
  data() {
    return {
      //附件
      annexAction: process.env.VUE_APP_URL + '/api/upload/importExcel',
      annexData: {type: 0}, //上传添加的字段
      annexName: '', //上传文件成功后文件的名称
    };
  },
  created() {
    if (this.$store.state.currentPermissions == 1) {
      this.annexData.type = 1;
    } else {
      this.annexData.type = 2;
    }
  },
  mounted() {
  },
  methods: {
    //附件下载
    annexDownload(url) {
      window.location = url
    },
    //附件 移除文件时的钩子
    annexDel(key) {
      this.$confirm('此操作将删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.quotationAttachment.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //附件 上传失败的钩子
    annexError() {
      this.$message.error('附件上传失败上传失败');
    },
    //附件 上传成功钩子
    annexSuccess(file) {
      let supplierData='';
      switch (this.$store.state.currentPermissions) {
        case 1:
          console.log(this.supplierData)
          for (let i = 0; i < file.data.length; i++) {
            let params={
              discountUnitPrice: file.data[i][4],//折后单价
              currency: file.data[i][5],//币种
              discount: file.data[i][6],//折扣
              totalPrice: file.data[i][7],//总价
              unitWeight: file.data[i][8],//单件重量
              unitOfWeight: file.data[i][9],//重量单位
              grossWeight: file.data[i][10],//总重量
              packageDimensions: file.data[i][11],//包装尺寸
              packingUnit: file.data[i][12],//包装单位
              deliveryTime: file.data[i][13],//货期
              deliveryUnit:file.data[i][14],//货期单位
            }
            this.commonExcelImportProp[i].list.push(params)
          }
          supplierData={
            supplier_name: '', // 供应商名称
            contacts: '',// 联系人
            job: '', // 职务
            phone: '',// 手机号
            wx: '',// QQ/微信
            email: '',// 邮箱
          }
          this.supplierData.push(supplierData)
         /*
          console.log(params)*/
          break
        default:
          for (let i = 0; i < file.data.length; i++) {
            let params = {
              brand_ch: file.data[i][0],
              brand_en: file.data[i][1],
              spec: file.data[i][2],
              number: file.data[i][3]
            }
            this.commonExcelImportProp.push(params)
          }
          this.$emit('seekTableInit')
          break

      }
    },
    //附件 上传文件之前的钩子
    annexUpload(file) {
      this.annexName = file.name;//名字存起来，传给后台要用
      const isText = (file.type === 'application/vnd.ms-excel' || file.type === 'application/pdf' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      if (!isText) {
        this.$message.error('上传附件格式错误!');
      }
      return file;
    },
    //附件 文件超出个数限制时的钩子
    annexExceed(files, fileList) {
      this.$message.error(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },

  },
};
</script>

<style lang="scss" scoped></style>
